import usePageBuilder from "../hooks/usePageBuilder";

const Index = (data) => {
  const { props } = usePageBuilder({ 
    content: data.pageContext.data.content,
    allImages: data.pageContext.data.images,
  });

  return props.children;
};

export default Index;
